'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var transitionProperty = function transitionProperty() {
	if (window.ontransitionend === undefined && window.onwebkittransitionend !== undefined) {
		return 'WebkitTransition';
	} else {
		return 'transition';
	}
};

exports.default = transitionProperty;