'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var cleanupAnimationClasses = function cleanupAnimationClasses() {
	document.documentElement.className.split(' ').forEach(function (classItem) {
		if (
		// remove "to-{page}" classes
		new RegExp('^to-').test(classItem) ||
		// remove all other classes
		classItem === 'is-changing' || classItem === 'is-rendering' || classItem === 'is-popstate') {
			document.documentElement.classList.remove(classItem);
		}
	});
};

exports.default = cleanupAnimationClasses;