'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _utils = require('../utils');

var _helpers = require('../helpers');

var getAnimationPromises = function getAnimationPromises() {
	var selector = this.options.animationSelector;
	var durationProperty = (0, _helpers.transitionProperty)() + 'Duration';
	var promises = [];
	var animatedElements = (0, _utils.queryAll)(selector, document.body);

	if (!animatedElements.length) {
		console.warn('[swup] No animated elements found by selector ' + selector);
		return [Promise.resolve()];
	}

	animatedElements.forEach(function (element) {
		var transitionDuration = window.getComputedStyle(element)[durationProperty];
		// Resolve immediately if no transition defined
		if (!transitionDuration || transitionDuration == '0s') {
			console.warn('[swup] No CSS transition duration defined for element of selector ' + selector);
			promises.push(Promise.resolve());
			return;
		}
		var promise = new Promise(function (resolve) {
			element.addEventListener((0, _helpers.transitionEnd)(), function (event) {
				if (element == event.target) {
					resolve();
				}
			});
		});
		promises.push(promise);
	});

	return promises;
};

exports.default = getAnimationPromises;